<template>
  <div style="height: 100%; background-color: midnightblue;">
    <!-- снег -->

    <div class="snowContainer">
      <v-dialog v-model="qr" fullscreen>
        <div style="background-color: midnightblue">
          <div style="text-align: center">
            <button
              style="margin-top: 5px"
              @click="qr = false"
              class="btn btn-outline-light"
            >
              Закрыть
            </button>
          </div>
          <qrReader v-if="qr" :checkAdmin="this.checkAdminQR"></qrReader>
        </div>
      </v-dialog>

      <v-dialog v-model="showURLinfo" width="590px">
        <div  style="background-color: midnightblue; color: white;display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap; padding: 10px;">
          <p>
            ACT старается использовать резервные каналы доступа к серверу. Теперь
            есть возможность менять их самостоятельно!
          </p>
          <p>
            Просто нажмите на кнопку смены сервера. Это должно помочь решить
            возникающие проблемы с подключением в некоторых случаях
          </p>
          <p>
            Если написано, что "0 возможных" - нажмите кнопку еще раз или обновите страницу. 
          </p>
         <p>В большинстве случаев сервер не доступен для вас по причине того, что браузер отказывается получать новые адреса. Почему? История браузера и КЭШ редко очищаются. Для обновления списка адресов вы можете попробовать очистить КЭШ браузера вручную или с помощью кнопки ниже</p>
         
         <button
            style="height: 40px; font-size: 14px; padding: 2px"
            @click="clearBrowserCache()"
            class="btn btn-outline-warning"
          >
          Попробовать очистить ВЕСЬ КЭШ браузера
          </button>
           <div style="color: bisque; font-size: 11px;">После нажатия на кнопку КЭШ будет очищен. КЭШ - это файлы сайтов, которые хранит браузер, чтобы те быстро открывались. Если очистить КЭШ - браузер просто будет загружать файлы заново. Ничего страшного в этом нет, но лучше не использовать, если вы стараетесь экономить трафик мобильного интернета
      </div>
          <p></p>
          <p>
            Адреса, которые я использую (могут быть разными и меняться со временем):
          
            <ol style="background-color: black;"> <li v-for="(el, i) in server_list" :key="i"> {{ el }}</li></ol>
            
            Возможно в некоторых случаях необходимо добавить их в список исключения антивируса. 
            <button
            style="height: 40px; font-size: 14px; padding: 2px"
            @click="showURL()"
            class="btn btn-outline-light"
          >
            Какой используется сейчас?
          </button>
          </p>
          <button
            style="height: 40px; font-size: 12px; padding: 2px"
            @click="changeURL()"
            class="btn btn-outline-info"
          >
            Сменить адрес сервера <br />(Сейчас используется
            {{ chageURLcounter + 1 }}-й из {{ chageURLlistCount }} возможных)
          </button>

         
          
          <div style="color: bisque; font-size: 11px;">Не нажимайте, если у вас уже есть подключение к серверу
      </div>
<br>
      
      <div>
        <p>Теперь можно попробовать подключаться по ключу, полученному через специальный телеграм-бот! Если не работает - напишите боту "Статус", он выдаст вам ключ. Введите ключ в это поле и нажмите кнопку ниже:</p>
           КЛЮЧ VK_ID: <input
                type="text"
                class="form-control"
                v-model="goToID_VKtunnel_temp"
              />
              <button
            style="height: 40px; font-size: 12px; padding: 2px"
            @click="goToID_VKtunnel()"
            class="btn btn-outline-info"
          >
            Попробовать подключиться по ключу (VK_Tunnel)
          </button>
          </div>

          <div>
            <br>
        <div>Аналогично можно подключиться по прямой ссылке сервера, (например https://url.ru - без слэша на конце):</div>
           Ссылка сервера: <input
                type="text"
                class="form-control"
                v-model="goToURL_temp"
              />
              <button
            style="height: 40px; font-size: 12px; padding: 2px"
            @click="goToURL()"
            class="btn btn-outline-info"
          >
            Попробовать подключиться по прямой ссылке
          </button>
          </div>
          <p>Имейте в виду, что некоторые из адресов ограничены на количество запросов в месяц. Я использую бесплатные инструменты для открытия доступа к серверу, при этом база данных хранится только на локальном компьютере</p>

         
          <button
            style="height: 40px; font-size: 14px; padding: 2px"
            @click="showURLinfo = false"
            class="btn btn-outline-light"
          >
            Понятно
          </button>
        </div>
      </v-dialog>

      <div  style="
      font-size: 10px;
          width: 100%;
          height: 15px;
          position: sticky;
          top: 0px; 
        ">
        <div
        v-if="classList.length === 0"
        style="
          font-size: 10px;
          width: 100%;
          height: 15px;
          display: flex;
          justify-content: center;
          background-color: brown;
         
        "
      >
        <b>Статус:</b>&nbsp;не удалось подключиться к серверу
      </div>
      <div
        v-else
        style="
          font-size: 10px;
          width: 100%;
          height: 15px;

          display: flex;
          justify-content: center;
          background-color: cornflowerblue;
         
        "
      >
        <b>Статус:</b>&nbsp;соединение с сервером установлено
      </div>

      </div>

      <div
        style="
          font-size: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          background-color: #343784;
          position: sticky;
            top: 15px; 
        "
      >
        <div >
          Версия от 07.03.2025 13:40(v0.278) 
          <span v-if="classList.length === 0"
            ><svg
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.0"
              width="12px"
              height="12px"
              viewBox="0 0 128 128"
              xml:space="preserve"
            >
              <rect x="0" y="0" width="100%" height="100%" fill="#343784" />
              <g>
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#000000"
                />
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#c0c0c0"
                  transform="rotate(45 64 64)"
                />
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#c0c0c0"
                  transform="rotate(90 64 64)"
                />
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#c0c0c0"
                  transform="rotate(135 64 64)"
                />
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#c0c0c0"
                  transform="rotate(180 64 64)"
                />
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#c0c0c0"
                  transform="rotate(225 64 64)"
                />
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#c0c0c0"
                  transform="rotate(270 64 64)"
                />
                <path
                  d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z"
                  fill="#c0c0c0"
                  transform="rotate(315 64 64)"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  values="0 64 64;45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64"
                  calcMode="discrete"
                  dur="720ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </span>
        </div>
        <div v-if="classList.length === 0">
          <button
            style="height: 40px; font-size: 12px; padding: 2px"
            @click="changeURL()"
            class="btn btn-outline-light"
          >
            Сменить адрес сервера <br />(Сейчас используется
            {{ chageURLcounter + 1 }}-й из {{ chageURLlistCount }} возможных)
          </button>
         
        </div>
      
        <div v-if="classList.length === 0">
          <div style="margin-bottom: 10px;">
            <br>
       
           Ссылка сервера: <input
                type="text"
                class="form-control"
                v-model="goToURL_temp"
              />
              <button
            style="height: 40px; font-size: 12px; padding: 2px"
            @click="goToURL()"
            class="btn btn-outline-info"
          >
            Попробовать подключиться по прямой ссылке
          </button>
          </div>
          <div style="margin-bottom: 10px;">
           КЛЮЧ VK_ID: <input
                type="text"
                class="form-control"
                v-model="goToID_VKtunnel_temp"
              />
              <button
            style="height: 40px; font-size: 12px; padding: 2px"
            @click="goToID_VKtunnel()"
            class="btn btn-outline-info"
          >
            Попробовать подключиться по ключу (VK_Tunnel)
          </button>
        </div>
          </div>
        <button @click="infoChangeServer()" style="color: aqua;"><u>Подробнее</u></button>
      </div>
      
    
      <div id="snow">
        <div id="container-main" style="display: flex; align-items: flex-start">
          <transition
            name="bounce"
            style="display: flex; align-items: flex-start"
          >
            <div
              v-if="showPosts_b"
              class="mainLogin"
              id="post"
              style="display: flex; align-items: flex-start"
            >
              <button
                @click="showPosts()"
                class="btn btn-outline-light"
                id="hideBtnPost"
              >
                Скрыть
              </button>

              <PostView :key="reRenderPostKey" />
            </div>
          </transition>
          <div v-if="!showPosts_b" id="showPosts">
            <button @click="showPosts()" class="btn btn-outline-light">
              Показать объявления
            </button>
          </div>
          <div id="login" class="mainLogin">
            <main style="width: 200px">
              <h2 style="align: center" translate="no">Вход в систему ACT</h2>

              Класс
              <select
                class="form-select"
                v-model="selectedClassID"
                @change="changeClass()"
              >
                <option
                  v-for="user in classList"
                  :key="user.className"
                  v-bind:value="user"
                >
                  {{ user.className }}
                </option>
              </select>

              Пароль

              <input
                type="password"
                id="password-input"
                class="form-control"
                v-model="password"
              />
              <div
                style="
                  display: flex;
                  justify-content: center;
                  color: darkgrey;
                  background-color: transparent;
                  font-size: 12px;
                  padding-top: 6px;
                  margin-left: 40px;
                  margin-right: 40px;
                  border-radius: 4px;
                "
              >
                <a style="" @click="show_hide_password()">
                  <span v-if="!checkShowPassword">Показать</span
                  ><span v-else>Скрыть</span> пароль
                </a>
              </div>
              <div
                v-if="!checkAuth"
                style="display: flex; justify-content: center"
              >
                <button
                  @click="auth()"
                  id="createReciept"
                  class="btn btn-outline-light"
                >
                  Войти
                </button>
              </div>
              <div
                style="display: flex; justify-content: center; margin-top: 30%"
              >
                <button @click="openQR()" class="btn btn-outline-light">
                  QR-сканер
                </button>
              </div>
              <div
                v-show="checkAuth"
                style="
                  display: flex;
                  justify-content: center;
                  background: transparent;
                "
              >
                <Preloader v-if="checkAuth" ref="Preloader" />
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>

    <!-- снег -->
  </div>
</template>

<script>
import ServerCommandLogin from "../services/ServerCommandLogin";
const Preloader = () => import("./Preloader.vue");
const PostView = () => import("./PostView.vue");
const qrReader = () => import("./qrReader.vue");
export default {
  components: {
    Preloader: Preloader,
    PostView: PostView,
    qrReader: qrReader,
  },
  data: function () {
    return {
      URL_list: [],
      goToID_VKtunnel_temp: "",
      goToURL_temp: "",
      chageURLlistCount: 0,
      chageURLcounter: 0,
      showURLinfo: false,
      reRenderPostKey: 0,
      checkAdminQR: false,
      qr: false,
      classList: [],
      selectedClassID: "",
      password: "",
      selectedClass: {},
      checkShowPassword: false,
      checkAuth: false,
      showPosts_b: true,
      server_list: []
    };
  },
  methods: {
    showURL() {
      let url = window.myVariable;
      alert(url);
    },
    infoChangeServer() {
      this.showURLinfo = true;
    },

    async goToID_VKtunnel() {
      window.myVariable = "https://user74052007-"+this.goToID_VKtunnel_temp+".tunnel.vk-apps.com/tutorials";
      this.classList = [];
      this.reRenderPostKey++;
      ServerCommandLogin.updateAxiosInstance();
      await this.del();
      await this.getClassList();
    },
    async goToURL() {
      window.myVariable = this.goToURL_temp+"/tutorials";
      this.classList = [];
      this.reRenderPostKey++;
      ServerCommandLogin.updateAxiosInstance();
      await this.del();
      await this.getClassList();
    },
   
    async changeURL() {
      
      if (window.URL_list == undefined) {
        document.getElementById("URL_GLOBAL").remove();
        let script = document.createElement("script");
        let d = Math.floor(Math.random() * 1000);
        script.id = "URL_GLOBAL";
        script.src = "https://test.actsch24.ru/test_script.js?version=" + d;
        document.head.append(script);
        script.onload = await function () {
          console.log("getUrl OK");
          ServerCommandLogin.updateAxiosInstance();
          this.chageURLlistCount = window.URL_list.length;
        };
      }
      this.chageURLcounter++;
      if (this.chageURLcounter >= window.URL_list.length) {
        this.chageURLcounter = 0;
      }
      window.myVariable = window.URL_list[this.chageURLcounter];
      this.classList = [];
      this.reRenderPostKey++;
      ServerCommandLogin.updateAxiosInstance();
      await this.del();
      await this.getClassList();
      
    },
    show_hide_password() {
      this.checkShowPassword = !this.checkShowPassword;
      var input = document.getElementById("password-input");
      if (input.getAttribute("type") == "password") {
        input.setAttribute("type", "text");
      } else {
        input.setAttribute("type", "password");
      }
      return false;
    },
    del() {
      localStorage.clear();
    },

    async getClassList() {
      await ServerCommandLogin.getAllCLass().then((res) => {
        let admin = {
          className: "АДМИНИСТРАТОР",
          classID: "admin",
        };
        let predmetnik = {
          className: "НАРУШЕНИЯ",
          classID: "violations",
        };
        this.classList = res.data.map(this.getDisplayClass);
        this.classList.push(admin);
        this.classList.push(predmetnik);
        this.chageURLlistCount = window.URL_list.length;
     
      });

    },
    changeClass() {
      this.$emit("example", this.selectedClassID);
      this.password = "";

      // ServerCommandLogin.auth(data).then((res) => {
      //   console.log(res);
      // });
    },

    auth() {
      if (this.selectedClassID.className != undefined) {
        let data = {
          _id: this.selectedClassID.classID,
          className: this.selectedClassID.className,
          password: this.password,
        };

        ServerCommandLogin.userLogin(data).then((res) => {
          if (res.data.auth) {
            this.checkAuth = true;
            localStorage.setItem("user", res.data.accessRights);
if (this.selectedClassID.className == "НАРУШЕНИЯ")
            this.$router.push("/Violations"); else this.$router.push("/dashboard");
          }
        });
      }
    },

    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
      };
    },

    showPosts() {
      function width100() {
        document.getElementById("login").style.width = "100%";
        if (window.innerWidth < 1024)
          document.getElementById("login").style.height = "100%";
      }
      this.showPosts_b = !this.showPosts_b;

      if (!this.showPosts_b) {
        setTimeout(width100, 530);
      } else {
        if (window.innerWidth > 1024) {
          document.getElementById("login").style.width = "50%";
          document.getElementById("login").style.height = "100%";
        } else {
          document.getElementById("login").style.height = "50%";
        }
      }
    },

    openQR() {
      this.checkAdminQR = false;
      let pas = prompt("ПАРОЛЬ(Оставьте пустым если не знаете):");
      if (pas == "123145") {
        this.qr = true;
        this.checkAdminQR = true;
      } else {
        this.qr = true;
        this.checkAdminQR = false;
      }
    },


   clearBrowserCache() {

  // Проверяем наличие поддержки Cache API
  if ('caches' in window) {
    caches.keys().then(function(cacheNames) {
      Promise.all(
        cacheNames.map(function(cacheName) {
          return caches.delete(cacheName);
        })
      ).then(function() {
        console.log('Все кэши успешно очищены');
        alert("Успешно!");
        window.location.reload(true);
      });
    });
  } else {
    console.error('Браузер не поддерживает Cache API');
  }
    },

    async checkServerStatus(urlss) {
      const headers = {
  "Content-type": "application/json",
    "ngrok-skip-browser-warning": 1,
    "Bypass-Tunnel-Reminder": 3,
    "bypass-tunnel-reminder": 1,
    "X-TOKEN": "keylogsch24",
    "Access-Control-Allow-Origin": "*",
  };
  const responses = await this.checkServerAvailability(urlss, headers);
  this.server_list = responses;

  
},

async checkServerAvailability(urls, headers = {}) {
  const responses = [];

  async function checkNextServer(index) {
    if (index >= urls.length) {
      responses.sort((a, b) => a.time - b.time);

      let arr = [];
      responses.forEach((element) => {
        arr.push(element.url);
      });
      window.URL_list = arr;
      window.myVariable = window.URL_list[0];
      ServerCommandLogin.updateAxiosInstance();
    
      await responses.forEach(({ url, time, status, response }) => {
        if (status === 200) {
          console.log(`${url} is available, response time: ${time}ms Server response: ${response}`);
        } else {
          console.log(`${url} is not available Server response: ${response}`);
        }
      });
      console.log(
        `The fastest server is ${responses[0].url}, response time: ${responses[0].time}ms`
      );
      return responses;
    } 
    const xhr = new XMLHttpRequest();
    await xhr.open('GET', `${urls[index]}`, true);

    // Установка заголовков
    for (const [key, value] of Object.entries(headers)) {
      xhr.setRequestHeader(key, value);
    }

    // Добавление дополнительного заголовка
    xhr.setRequestHeader("bypass-tunnel-reminder", "1");

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        const responseTime = Math.round(performance.now() - xhr.startTime);
        if (xhr.responseText.length > 0) {
          responses.push({
            url: `${urls[index]}`,
            time: responseTime,
            status: xhr.status,
            response: xhr.responseText.length,
          });
          
        }
        checkNextServer(index + 1);
      }
    };
    xhr.startTime = performance.now();

    await xhr.send();
  }

  await checkNextServer(0, this.server_list);
  
  return responses;
}
  
  },
  async mounted() {
    if (window.URL_list == undefined) {
      let script = document.createElement("script");
      document.getElementById("URL_GLOBAL").remove();
      let d = new Date().getTime();
      script.id = "URL_GLOBAL";
      script.src = "https://test.actsch24.ru/test_script.js?version=" + d;
      document.head.append(script);
      script.onload = await function () {
        console.log("getUrl OK");
        
        ServerCommandLogin.updateAxiosInstance();
        this.checkServerStatus(window.URL_list);
        this.chageURLlistCount = window.URL_list.length;
        this.URL_list = window.URL_list;
      };
     
    } else {
      let script = document.createElement("script");
      document.getElementById("URL_GLOBAL").remove();
      let d = new Date().getTime();
      script.id = "URL_GLOBAL";
      script.src = "https://test.actsch24.ru/test_script.js?version=" + d;
      document.head.append(script);
      script.onload = await function () {
        console.log("getUrl OK");
        
        ServerCommandLogin.updateAxiosInstance();
        this.checkServerStatus(window.URL_list);
        this.chageURLlistCount = window.URL_list.length;
        this.URL_list = window.URL_list;
      };
      await this.checkServerStatus(window.URL_list);
      this.chageURLlistCount = window.URL_list.length;
      this.URL_list = window.URL_list;
    }
    this.del();
    this.getClassList();
    this.password = "";
  },
  watch: {
    password: function () {
      if (this.password.length > 6) {
        this.auth();
      } else {
        this.checkAuth = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.snowContainer div {
  background-color: rgba(0, 72, 172, 0);

  background-size: 50%;
  background-repeat: repeat;
  color: white;
}
.mainLogin {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;

  background-color: rgba(240, 255, 255, 0);
}
.mainLogin main {
  width: 250px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(240, 255, 255, 0);

  h2 {
    display: flex;
    width: 300px;
    align-content: center;
    margin-left: -35px;
  }
}

// снег
.snowContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
#snow {
  background-color: rgb(0, 72, 172);
  width: 100%;
  height: 94.8vh;
  //  background-image: url("../assets/15825154.png"), url("../assets/89961317.png"), url("../assets/21791255.png");
  //  -webkit-animation: snow 40s linear infinite;
  //  -moz-animation: snow 40s linear infinite;
  //  -ms-animation: snow 40s linear infinite;
  //  animation: snow 40s linear infinite;
}
@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  100% {
    background-position: 100% 600px, 400px 400px, 300px 300px;
  }
}
@-moz-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  100% {
    background-position: 300px 600px, 400px 400px, 300px 300px;
  }
}
@-webkit-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  100% {
    background-position: 300px 600px, 400px 400px, 300px 300px;
  }
}
@-ms-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
  }
}

// снег

#post {
  width: 50%;
  background-color: #7a7fff4c;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 50%;
  }

  scrollbar-color: #343784 #2a267100; /* «цвет ползунка» «цвет полосы скроллбара» */

  scrollbar-width: thin;
}
#post::-webkit-scrollbar {
  width: 4px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: #14386100;
}

#post::-webkit-scrollbar-thumb {
  background-color: #343784;
  border-radius: 3px;
  max-width: 3px;
}

#login {
  width: 50%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 50%;
  }
}

#container-main {
  flex-wrap: nowrap;
  height: 100%;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
  }
}

// #btn-show-posts {
//   display: flex;
//   justify-content: center;
//   align-items: stretch;
//   transform: rotate(90deg);
//   @media screen and (max-width: 1024px) {
//     transform: none;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     //  width: 30px;
//     margin-bottom: 100px;
//   }
// }

#btn-show-posts :first-child {
  height: 40px;
  margin-top: 49vh;
  @media screen and (max-width: 1024px) {
    height: 40px;
    margin-top: 30%;
  }
}
#showPosts {
  position: fixed;
  transform: rotate(90deg);
  top: 50vh;
  margin-left: -80px;
  @media screen and (max-width: 1024px) {
    top: 95vh;
    transform: none;
    margin-left: 0;
  }
}
#showPosts :first-child {
  @media screen and (max-width: 1024px) {
    @media screen and (orientation: portrait) {
      margin-top: -30vh;
    }
  }
}

#hideBtnPost {
  position: fixed;
  transform: rotate(90deg);
  top: 50vh;
  margin-left: -47vw;
  @media screen and (max-width: 1024px) {
    position: static;
    transform: none;
    top: 0px;
    margin: 0;
    align-items: flex-start;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
